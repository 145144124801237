export const AnalyticsEventType = "analytics-event";

export interface AnalyticsEventDetail {
    event_name: string;
    product_id: string;
    product_name: string;
    currency?: string;
    price?: string;
}

export const AnalyticsEvents = {
  addToCart: "add_to_cart",
  addToWishlist: "add_to_wishlist",
  beginCheckout: "begin_checkout",
  purchase: "purchase",
};
